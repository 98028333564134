import { lazy } from "react";
const ById = lazy(() => import("../component/Blogs/by_id"));
const Home = lazy(() => import("../component/Home"));
const Signing = lazy(() => import("../component/Signing"));
const Admin = lazy(() => import("../component/Admin"));
const Blogs = lazy(() => import("../component/Blogs"));
const Media = lazy(() => import("../component/Media/new_index"));
const MediaAdmin = lazy(() => import("../component/Admin/media"));
const ESport = lazy(() => import("../component/Admin/by_game"));
const AddBlog = lazy(() => import("../component/Admin/add_blog"));
const BlogAdmin = lazy(() => import("../component/Admin/Blog"));

export const Data = [
  {
    id: 1,
    path: "/",
    component: Home,
  },
  {
    id: 3,
    path: "/signin",
    component: Signing,
  },
  {
    id: 4,
    path: "/blogs",
    component: Blogs,
  },
  {
    id: 41,
    path: "/blogs/:id",
    component: ById,
  },
  {
    id: 5,
    path: "/media",
    component: Media,
  },
];
export const AdminData = [
  {
    id: 1,
    path: "/admin",
    component: Admin,
  },
  {
    id: 2,
    path: "/admin/E-Sports",
    component: ESport,
  },
  {
    id: 3,
    path: "/admin/blog",
    component: BlogAdmin,
  },
  {
    id: 31,
    path: "/admin/blog-add",
    component: AddBlog,
  },
  {
    id: 22,
    path: "/admin/blog-edit/:id",
    component: AddBlog,
  },
  {
    id: 41,
    path: "/admin/media",
    component: MediaAdmin,
  },
];
